.Ecoturismo {
    height: auto;
    min-height: 100vh;
    width: 100%;
    padding: 3.5rem 3rem;
    color: var(--fontColor);
    display: flex;
    flex-direction: row;
}

#eco__left {
    height: 100%;
    width: 50%;
    /* border: 1px solid red; */
    margin-right: 2rem;
}

#eco__right {
    height: 100%;
    width: 40%;
    margin-left: 5rem;
    background: transparent;
    box-shadow: 10px 10px 0 var(--fontColor);
    z-index: 2;
    min-height: 80vh;
}

#eco__right_verona {
    min-height: 80vh;
    height: 100%;
    width: 100%;
    background: url('../../assets/images/verona/verona_4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    transition: var(--defaultTransition);
    cursor: pointer;
}

#eco__right_verona:hover {
    transform: scale(1.05);
    filter: drop-shadow(0 0 10px #000);
}

#eco__right_verona:hover>#zoom_hover {
    opacity: 1;
}

#zoom_hover {
    height: 100%;
    min-height: 80vh;
    width: 100%;
    background: #2b2b2b99;
    opacity: 0;
    transition: var(--defaultTransition);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#zoom_label {
    margin-top: 2rem;
    color: white !important;
}

#eco__left_body {
    height: auto;
    width: 100%;
}

#eco__left_body p {
    width: auto;
    margin: 2rem 0 3rem 3rem;
    font-size: 1.3rem;
    font-family: Montserrat-Medium !important;
    text-align: justify;
    letter-spacing: 0 !important;
}

#eco__left_services {
    height: auto;
    width: 100%;
    display: grid;
    place-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 0px 0px;
}

.service {
    height: auto;
    width: 80%;
    margin: 2rem;
    /* border: 1px solid red; */
    padding: 1rem 2rem;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    font-size: 1rem;
    font-family: Montserrat-SemiBold;
    color: #000 !important;
    border-radius: 8px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, .3);
}

.service img {
    margin-right: 1rem;
    height: 3.5rem;
}

@media only screen and (max-width: 1200px) {
    .Ecoturismo {
        height: auto;
        flex-direction: column;
        align-items: center;
        padding: 2rem 1rem;
    }

    #eco__left {
        width: 100%;
        height: auto;
        min-height: 100vh;
        margin-right: 0;
        margin-bottom: 2rem;
    }

    #eco__right {
        width: 100%;
        max-height: 50vh;
        min-height: 50vh;
        margin-left: 0;
        background: url('../../assets/images/verona/verona_4.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    #eco__left_body p {
        margin: 2rem 0;
        font-size: 1.5rem;
        text-align: start;
    }


    #eco__left_services {
        display: flex;
        flex-direction: column;
    }

    .service {
        margin: 0;
        margin-bottom: 2rem;
        box-shadow: none;
        border: 2px solid #000;
    }

    #zoom_hover {
        display: none;
    }

    #eco__right_verona {
        background: none;
    }

    #eco__right_verona:hover {
        transform: unset;
        filter: unset;
    }

}