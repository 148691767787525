.PCViewNavbar {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1.2rem;
    font-family: Montserrat-Semibold;
    transition: var(--defaultTransition);
    letter-spacing: 2px;
}

.PCViewNavbar a {
    margin: 0 2rem;
    cursor: pointer;
    transition: var(--defaultTransition);
    position: relative;
    border: none;
}

.Navbar__Dark {
    background: #2e2e2e90;
}

.Navbar__Hide {
    margin-top: -10rem;
}

.nav__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.nav__content_display {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    animation: .35s fadeIn;
}

#nav__logo {
    margin: 1rem 0;
    margin-right: 5rem !important;
    transition: var(--defaultTransition);
}

#nav__logo:hover {
    transform: scale(1.05);
}

#nav__logo::before {
    display: none !important;
}

.PCViewNavbar a::before {
    content: '';
    height: .2rem;
    width: 0;
    background: #fff;
    position: absolute;
    bottom: -.2rem;
    transition: var(--defaultTransition);
}

.PCViewNavbar a:hover::before {
    width: 100%;
}

#langSelect {
    height: auto;
    width: auto;
    margin: 0 3rem;
}

#langSelect button {
    cursor: pointer;
    height: auto;
    width: auto;
    background: transparent;
    border: none;
}

#langSelect img {
    height: 2.5rem;
}

#otherLangs {
    display: flex;
    flex-direction: column;
    position: absolute;
}

.btn__Flag {
    opacity: .6;
    transition: var(--defaultTransition);
}

.btn__Flag:hover {
    opacity: 1;
}