.PageTitle {
    display: flex;
    align-items: center;
}

.PageTitle h1 {
    font-family: Montserrat-Bold;
    font-size: 3rem;
    z-index: 1;
    background: #fff;
    padding: 0 1rem;
    color: var(--fontColor);
}

/* .pageTitle_dark {
    background: #2e2e2e !important;
    text-shadow: 1px 1px 2px #fff;
}

.pageTitle_light {
    background: #fff !important;
} */

#header-line {
    height: 5px;
    width: 100%;
    background: var(--orangeKey);
    position: absolute;
    left: 0;
    z-index: 0;
}

@media only screen and (max-width: 1200px) {
    #header-line {
        display: none;
    }

    .PageTitle h1 {
        text-align: center;
        width: 100%;
        /* border-bottom: 5px solid var(--orangeKey); */
        filter: drop-shadow(6px 6px 0 var(--orangeKey));
        /* border-top: 5px solid var(--orangeKey); */
    }
}