.Modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 9999;
    display: grid;
    place-items: center;
    background: rgba(0, 0, 0, .9);
}

.modal__content {
    height: 90vh;
}

#modal__close {
    position: fixed;
    top: 2rem;
    right: 2rem;
    border: none;
    background: transparent;
    cursor: pointer;
    transition: var(--defaultTransition);
    opacity: .5;
}

#modal__close:hover {
    opacity: 1;
}

@media only screen and (max-width: 1200px) {
    .modal__content {
        width: 60%;
        height: auto;
    }
}

@media screen and (max-width: 600px) {
    .modal__content {
        width: 90%;
        height: auto;
    }

    .Modal {
        /* height: 100vh; */
        height: calc(var(--vh, 1vh) * 120);
    }
}