.Notification {
    height: auto;
    width: auto;
    max-width: 30%;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    background: #fff;
    border-radius: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    font-size: 1rem;
    z-index: 9998;
}

.Notification img {
    margin-right: 1rem;
    height: 150%;
}

#not_1 {
    border: none;
    background: none;
    position: absolute;
    top: .5rem;
    right: .5rem;
    opacity: .5;
    transition: var(--defaultTransition);
    cursor: pointer;
    font-size: 1.1rem;
}

#not_1:hover {
    opacity: 1;
}

#not_2 {
    display: none;
    border: none;
    background: none;
    position: absolute;
    bottom: .5rem;
    right: .5rem;
    cursor: pointer;
    font-size: 1rem;
    border: 2px solid var(--orangeKey);
    padding: 5px;
}

@media only screen and (max-width: 900px) {
    .Notification {
        bottom: 0;
        left: 0;
        width: 100%;
        max-width: unset;
        border-radius: 0;
        box-shadow: 0 -5px 10px rgba(0, 0, 0, .3);
    }

    #not_1 {
        display: none;
    }

    #not_2 {
        display: unset;
    }
}