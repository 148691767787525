.MobileViewNavbar {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    width: 100%;
    background: var(--fontColor);
    transition: var(--defaultTransition);
}

.MobileViewNavbar_display {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    transition: var(--defaultTransition);

}

.navbar__hamburger {
    position: absolute;
    top: 2rem;
    right: 2rem;
    display: grid;
    place-items: center;
    background: none;
    border: none;
}

#hamburger_stick1,
#hamburger_stick2 {
    width: 3rem;
    height: 5px;
    background: #000;
    filter: drop-shadow(0 0 3px #fff);
    transition: var(--defaultTransition);
    margin: .35rem 0;
}

.MobileViewNavbar_display #hamburger_stick1 {
    transform: rotate(-45deg);
    filter: none;
    background: #fff;
}

.MobileViewNavbar_display #hamburger_stick2 {
    transform: rotate(45deg);
    position: absolute;
    filter: none;
    background: #fff;
}

.MobileViewNavbar__content {
    display: none;
}

.MobileViewNavbar__content_display {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    width: 100%;
}

.MobileViewNavbar__content a {
    margin: 2rem 0;
    color: #fff;
    font-size: 1.5rem;
}

#MobileViewNavbar__langSelect {
    height: auto;
    width: auto;
}

#MobileViewNavbar__langSelect button {
    height: auto;
    width: auto;
    background: transparent;
    border: none;
    margin: 0 2rem;
}

#MobileViewNavbar__langSelect img {
    height: 2.5rem;
}

.MobileViewNavbar__btn__Flag {
    opacity: 1;
    transition: var(--defaultTransition);
}

@media only screen and (min-height: 650px) {
    #MobileViewNavbar__langSelect {
        margin-top: 5rem;
    }

    .MobileViewNavbar__content_display {
        height: 100%;
    }
}