.Rooms {
    height: auto;
    width: 100%;
    padding: 0 3.5rem;
    color: var(--fontColor);
}

#orchidea_bianca,
#zaffiro,
#ametista {
    height: auto;
    width: 100%;
    margin-top: 5rem;
    display: flex;
    flex-direction: row;
}

.room_desc {
    width: 55%;
    height: auto;
    display: flex;
    flex-direction: column;
    font-size: 1.3rem;
}

.room_desc_1 {
    margin-bottom: 1rem;
}

.room_desc h1 {
    font-size: 2.5rem;
    font-family: Montserrat-Bold;
    letter-spacing: 2px;
    margin-bottom: 2rem;
}


.room_services {
    list-style-type: none;
    margin: .5rem 2rem;
    margin-bottom: 5rem;
}

.room_services li::before {
    /* content: '— '; */
    content: '- ';
}

.room_images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2fr 1fr;
    grid-auto-columns: 1fr;
    gap: 10px 10px;
    grid-auto-flow: row;
    grid-template-areas:
        "room_img_1 room_img_1"
        "room_img_2 room_img_3";

    width: 45%;
    height: 100%;
    min-height: 80vh;
    margin-left: 2rem;
}

.room_images a {
    text-decoration: underline;
    font-family: Montserrat-SemiBold;
    opacity: .7;
    transition: var(--defaultTransition);
}

.room_images a:hover {
    opacity: 1;
}

.room_img_1 {
    grid-area: room_img_1;
    height: min-content;
    margin: 0;
}

.room_img_2 {
    grid-area: room_img_2;
    height: min-content;
}

.room_img_3 {
    grid-area: room_img_3;
    height: min-content;
}

.room_img_1 img,
.room_img_2 img,
.room_img_3 img {
    width: 100%;
    transition: var(--defaultTransition);
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0);
}

.room_img_1 img:hover,
.room_img_2 img:hover,
.room_img_3 img:hover {
    transform: scale(1.03);
    box-shadow: 0 0 15px rgba(0, 0, 0, .8);
}

.r_img_zaffiro {
    margin-left: 0;
    margin-right: 3rem;
}


@media only screen and (max-width: 1200px) {
    .Rooms {
        padding: 1rem;
    }

    #orchidea_bianca,
    #zaffiro,
    #ametista {
        flex-direction: column;
    }

    #zaffiro {
        flex-direction: column-reverse;
    }

    .room_desc,
    .room_images {
        width: 100% !important;
    }

    .room_images {
        margin: 0;
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

}