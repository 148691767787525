.GoBookings {
    margin-top: 5rem;
    height: 30rem;
    width: 100%;
    background: url('../../assets/images/verona/banner.png');
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    padding: 2rem;
}

.GoBookings h1 {
    font-size: 3rem;
    font-family: Montserrat-Regular;
    letter-spacing: 2px;
    margin: 3rem 0;
}

.GoBookings a {
    border: 5px solid var(--orangeKey);
    font-size: 1.5rem;
    padding: 2rem;
    cursor: pointer;
    transition: var(--defaultTransition);
}

.GoBookings a:hover {
    background: var(--orangeKey);
}

@media only screen and (max-width: 1200px) {
    .GoBookings {
        text-align: center;
        height: 25rem;
    }

    .GoBookings a {
        padding: 1rem;
    }
}