.Footer {
    /* height: 25vh; */
    height: auto;
    width: 100%;
    background: #2e2e2e;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 2px solid #fff;
    padding: 1rem 0;
}

.footer__box {
    width: auto;
    height: 100%;
    /* border-right: 2px solid rgba(255, 255, 255, .5); */
    padding: 1rem 2rem;
    color: #fff;
}

.footer__divider {
    height: 180px;
    width: 2px;
    background: rgba(255, 255, 255, .5);
}

.footer__logo {
    display: grid;
    place-items: center;
    width: fit-content;
}

.footer__logo img {
    width: 70%;
}

.footer__box h3 {
    opacity: .5;
    font-family: Montserrat-Regular;
}

.footer__privacy {
    border: none;
}

.footer__links div,
.footer__privacy div {
    display: flex;
    flex-direction: column;
    margin: .5rem;
}

.footer__links a,
.footer__privacy a {
    margin: .3rem 0;
    cursor: pointer;
}

.footer__links a:hover,
.footer__privacy a:hover {
    text-decoration: underline;
}

#footer__contacts_box {
    display: flex;
    flex-direction: row;
    margin-top: .5rem;
    line-height: 1.3rem;
}

#footer__contacts_box div {
    margin: 0 1rem;
}

#footer__contacts_box a {
    opacity: .5;
    transition: var(--defaultTransition);
    cursor: pointer;
}

#footer__contacts_box a:hover {
    opacity: 1;
    text-decoration: underline;
}

@media screen and (max-width: 1400px) {
    #footer__contacts_box div {
        margin: 0 .5rem;
    }

    .footer__logo img {
        width: 100%;
    }

    .footer__logo {
        width: 20%;
    }
}

@media screen and (max-width: 1200px) {
    .footer__links {
        display: none;
    }
}

@media screen and (max-width: 900px) {
    .footer__divider {
        display: none;
    }

    .footer__box div {
        align-items: flex-start;
    }

    .Footer {
        flex-direction: column;
        align-items: flex-start;
    }

    .footer__logo {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    #footer__contacts_box {
        flex-direction: column;
    }

    #footer__contacts_indirizzo a {
        line-height: 3rem;
    }
}