.Privacy {
    min-height: 100vh;
    height: auto;
    width: 100%;
    background: var(--darkKey);
    padding: 8rem 8rem 1rem 8rem;
    color: white;
}

.privacy_h1 {
    font-size: 3rem;
}

.privacy_p {
    width: 100%;
    margin-bottom: 2rem;
}

.privacy_link {
    text-decoration: underline;
    opacity: .6;
    transition: var(--defaultTransition);
}

.privacy_link:hover {
    opacity: 1;
}

.privacy_ul {
    margin-left: 2rem;
}

@media only screen and (max-height: 800px) {
    .Privacy {
        padding: 3rem 2rem 1rem 2rem;
    }
}