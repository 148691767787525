.Contacts {
    height: auto;
    width: 100%;
    padding: 2rem;
    color: var(--fontColor);
    margin-bottom: 2rem;
}

.Contacts p {
    font-size: 1.3rem;
    margin: 2rem 1rem;
}

#contact__boxes {
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-auto-columns: 1fr;
    gap: 10px 10px;
    grid-auto-flow: row;
    grid-template-areas: "c__box_left c__box_mid c__box_right";
}

.contact__box {
    min-height: 30vh;
    border-right: 2px solid rgba(0, 0, 0, .3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.c__box_left {
    align-items: unset;
}

.c__box_left p {
    margin-left: 5rem;
}

#contacts__btn_box {
    display: flex;
    flex-direction: column;
}

.c__box_mid a {
    background: red;
    padding: 1rem;
    margin: 1rem 0;
    color: #fff;
    font-family: Montserrat-SemiBold;
    font-size: 1.3rem;
    /* width: 50%; */
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: var(--defaultTransition);
}

.c__box_mid a:hover {
    filter: drop-shadow(0 0 8px rgba(0, 0, 0, .5));
    transform: scale(1.05);
}

.c__box_mid img {
    margin-right: 1rem;
}

.c__box_right {
    border: none;
}

.c__box_right iframe {
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 1200px) {
    #contact__boxes {
        display: flex;
        flex-direction: column;
    }

    .contact__box {
        border-right: unset;
        border-bottom: 2px solid rgba(0, 0, 0, .3);
    }

    .c__box_mid a:hover {
        filter: unset;
        transform: unset;
    }

    .c__box_mid a:active {
        filter: drop-shadow(0 0 8px rgba(0, 0, 0, .5));
        transform: scale(1.05);
    }
}