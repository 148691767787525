.Navbar {
    height: auto;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999 !important;
}

.navbar__fixed {
    position: fixed;
}

.navbar__absolute {
    position: absolute;
    /* top: 3rem; */
}

.PCViewNavbarContainer {
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 1200px) {
    .PCViewNavbarContainer {
        display: none;
    }

    #nav__logo {
        margin-right: 2rem !important;
    }
}

@media only screen and (min-width: 1200px) {
    .MobileViewNavbarContainer {
        display: none;
    }
}