.NotFound {
    height: 100vh;
    width: 100%;
    background: #2e2e2e;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#notfound-info a {
    margin: 1rem 0;
    text-decoration: underline;
}