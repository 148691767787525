.Gallery {
    height: auto;
    width: 100%;
    background: #2e2e2e;
    padding: 8rem 2rem 0 2rem;
}

.Gallery h1 {
    text-align: center;
    font-size: 3rem;
    margin: 0 0 3rem 1rem;
    color: #fff;
}

#gallery-container {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    padding: 0 12px;
}

.gallery-image {
    transition: var(--defaultTransition);
    margin-bottom: 12px;
    width: 100%;
    cursor: pointer;
}

.gallery-image:hover {
    transform: scale(1.03);
}

#TagSelector {
    width: 100%;
    height: auto;
    padding: 1rem .5rem;
    margin-bottom: 2rem;
}

#tags_results {
    color: white;
    font-size: .8rem;
    margin-left: .5rem;
}

.btn__tagselector {
    margin-right: .5rem;
    margin-bottom: .5rem;
    padding: .6rem .8rem;
    font-family: Montserrat-SemiBold;
    color: #fff;
    background: rgba(255, 255, 255, .1);
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: var(--defaultTransition);
}

.btn__tagselector:hover {
    background: rgba(255, 255, 255, .4);
}

.btn__tagselector_active {
    background: rgba(255, 255, 255, .65);
    color: black;
}


@media screen and (max-width: 900px) {
    #gallery-container {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }

    .Gallery {
        padding: 5rem .5rem 0 .5rem;
        min-height: calc(var(--vh, 1vh) * 100);
    }

    .btn__tagselector {
        font-size: 1rem;
    }
}

@media screen and (max-width: 500px) {
    #gallery-container {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
    }
}