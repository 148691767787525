@font-face {
    font-family: Montserrat-Regular;
    src: url('../fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: Montserrat-Medium;
    src: url('../fonts/Montserrat-Medium.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: Montserrat-SemiBold;
    src: url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: Montserrat-Bold;
    src: url('../fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: 800;
}

html,
body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    background: #FBFAF5;
    font-family: Montserrat-Medium;
    min-width: 500px;
    min-height: 400px;
    overflow: overlay;
    overflow-x: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
}

* a {
    text-decoration: none;
    color: unset;
}

* img {
    image-rendering: optimizeQuality;
    /* pointer-events: none; */
}

:root {
    --fontColor: #6E3219;
    --orangeKey: #c4651b;
    --whatsappColor: #25D366;
    --callmeColor: #34B7F1;
    --emailmeColor: #666666;
    --bookingsColor: #003580;
    --darkKey: #2e2e2e;
    --defaultTransition: .2s ease-out;
}

::-webkit-scrollbar {
    width: 15px;
}

::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.8);
    background-clip: padding-box;
}

.App {
    height: auto;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: none;
}

/* @media screen and (min-width: 320px) {
    html {
        font-size: calc(12px + 6 * ((100vw - 320px) / 680));
    }
}

@media screen and (min-width: 1000px) {
    html {
        font-size: 16px;
    }
} */