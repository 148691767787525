@font-face {
    font-family: Montserrat-Regular;
    src: url(/static/media/Montserrat-Regular.9c460951.ttf) format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: Montserrat-Medium;
    src: url(/static/media/Montserrat-Medium.a98626e1.ttf) format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: Montserrat-SemiBold;
    src: url(/static/media/Montserrat-SemiBold.c88cecbf.ttf) format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: Montserrat-Bold;
    src: url(/static/media/Montserrat-Bold.88932dad.ttf) format('truetype');
    font-weight: 800;
}

html,
body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    background: #FBFAF5;
    font-family: Montserrat-Medium;
    min-width: 500px;
    min-height: 400px;
    overflow: overlay;
    overflow-x: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
}

* a {
    text-decoration: none;
    color: unset;
}

* img {
    image-rendering: optimizeQuality;
    /* pointer-events: none; */
}

:root {
    --fontColor: #6E3219;
    --orangeKey: #c4651b;
    --whatsappColor: #25D366;
    --callmeColor: #34B7F1;
    --emailmeColor: #666666;
    --bookingsColor: #003580;
    --darkKey: #2e2e2e;
    --defaultTransition: .2s ease-out;
}

::-webkit-scrollbar {
    width: 15px;
}

::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.8);
    background-clip: padding-box;
}

.App {
    height: auto;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: none;
}

/* @media screen and (min-width: 320px) {
    html {
        font-size: calc(12px + 6 * ((100vw - 320px) / 680));
    }
}

@media screen and (min-width: 1000px) {
    html {
        font-size: 16px;
    }
} */
.Navbar {
    height: auto;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999 !important;
}

.navbar__fixed {
    position: fixed;
}

.navbar__absolute {
    position: absolute;
    /* top: 3rem; */
}

.PCViewNavbarContainer {
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 1200px) {
    .PCViewNavbarContainer {
        display: none;
    }

    #nav__logo {
        margin-right: 2rem !important;
    }
}

@media only screen and (min-width: 1200px) {
    .MobileViewNavbarContainer {
        display: none;
    }
}
.PCViewNavbar {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1.2rem;
    font-family: Montserrat-Semibold;
    transition: var(--defaultTransition);
    letter-spacing: 2px;
}

.PCViewNavbar a {
    margin: 0 2rem;
    cursor: pointer;
    transition: var(--defaultTransition);
    position: relative;
    border: none;
}

.Navbar__Dark {
    background: #2e2e2e90;
}

.Navbar__Hide {
    margin-top: -10rem;
}

.nav__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.nav__content_display {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    -webkit-animation: .35s fadeIn;
            animation: .35s fadeIn;
}

#nav__logo {
    margin: 1rem 0;
    margin-right: 5rem !important;
    transition: var(--defaultTransition);
}

#nav__logo:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

#nav__logo::before {
    display: none !important;
}

.PCViewNavbar a::before {
    content: '';
    height: .2rem;
    width: 0;
    background: #fff;
    position: absolute;
    bottom: -.2rem;
    transition: var(--defaultTransition);
}

.PCViewNavbar a:hover::before {
    width: 100%;
}

#langSelect {
    height: auto;
    width: auto;
    margin: 0 3rem;
}

#langSelect button {
    cursor: pointer;
    height: auto;
    width: auto;
    background: transparent;
    border: none;
}

#langSelect img {
    height: 2.5rem;
}

#otherLangs {
    display: flex;
    flex-direction: column;
    position: absolute;
}

.btn__Flag {
    opacity: .6;
    transition: var(--defaultTransition);
}

.btn__Flag:hover {
    opacity: 1;
}
.MobileViewNavbar {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    width: 100%;
    background: var(--fontColor);
    transition: var(--defaultTransition);
}

.MobileViewNavbar_display {
    height: 100vh;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    transition: var(--defaultTransition);

}

.navbar__hamburger {
    position: absolute;
    top: 2rem;
    right: 2rem;
    display: grid;
    place-items: center;
    background: none;
    border: none;
}

#hamburger_stick1,
#hamburger_stick2 {
    width: 3rem;
    height: 5px;
    background: #000;
    -webkit-filter: drop-shadow(0 0 3px #fff);
            filter: drop-shadow(0 0 3px #fff);
    transition: var(--defaultTransition);
    margin: .35rem 0;
}

.MobileViewNavbar_display #hamburger_stick1 {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-filter: none;
            filter: none;
    background: #fff;
}

.MobileViewNavbar_display #hamburger_stick2 {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    position: absolute;
    -webkit-filter: none;
            filter: none;
    background: #fff;
}

.MobileViewNavbar__content {
    display: none;
}

.MobileViewNavbar__content_display {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    width: 100%;
}

.MobileViewNavbar__content a {
    margin: 2rem 0;
    color: #fff;
    font-size: 1.5rem;
}

#MobileViewNavbar__langSelect {
    height: auto;
    width: auto;
}

#MobileViewNavbar__langSelect button {
    height: auto;
    width: auto;
    background: transparent;
    border: none;
    margin: 0 2rem;
}

#MobileViewNavbar__langSelect img {
    height: 2.5rem;
}

.MobileViewNavbar__btn__Flag {
    opacity: 1;
    transition: var(--defaultTransition);
}

@media only screen and (min-height: 650px) {
    #MobileViewNavbar__langSelect {
        margin-top: 5rem;
    }

    .MobileViewNavbar__content_display {
        height: 100%;
    }
}
.Intro {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    background-image: url(/static/media/bg5.93a5e86e.png);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    transition: .3s ease-in;
}

#intro__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#logo {
    width: 50vh;
    margin-bottom: 10vh;
    -webkit-animation: 1s logoFadeIn;
            animation: 1s logoFadeIn;
}

@-webkit-keyframes logoFadeIn {
    from {
        margin-right: 10%;
        opacity: 0;
    }

    to {
        margin-right: unset;
        opacity: 1;
    }
}

@keyframes logoFadeIn {
    from {
        margin-right: 10%;
        opacity: 0;
    }

    to {
        margin-right: unset;
        opacity: 1;
    }
}

#btn__toStanze {
    font-size: 1.5rem;
    text-align: center;
    padding: 1.5rem;
    border: 5px solid var(--orangeKey);
    cursor: pointer;
    background: transparent;
    transition: var(--defaultTransition);
    position: relative;
    -webkit-animation: 1s btnToStanzeFadeIn;
            animation: 1s btnToStanzeFadeIn;
}

@-webkit-keyframes btnToStanzeFadeIn {
    from {
        margin-left: 10%;
        opacity: 0;
    }

    to {
        margin-left: unset;
        opacity: 1;
    }
}

@keyframes btnToStanzeFadeIn {
    from {
        margin-left: 10%;
        opacity: 0;
    }

    to {
        margin-left: unset;
        opacity: 1;
    }
}

#btn__toStanze:hover {
    background-color: var(--orangeKey);
}

/* #btn__toStanze span::after {
    content: '»';
    position: absolute;
    top: 1.5rem;
    margin-left: 0;
    opacity: 0;
    transition: var(--defaultTransition);
}

#btn__toStanze:hover span {
    margin-right: 1rem;
    transition: var(--defaultTransition);
}

#btn__toStanze:hover span::after {
    margin-left: .5rem;
    opacity: 1;
} */

#bg__arrow_right {
    right: 0;
}

#bg__arrow_left,
#bg__arrow_right {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    height: 6rem;
    cursor: pointer;
    transition: var(--defaultTransition);
    display: grid;
    place-items: center;
    background: transparent;
    border: none;
    border-radius: 5px;
    opacity: .5;
}

#bg__arrow_left img,
#bg__arrow_right img {
    height: 3.5rem;
}

#bg__arrow_left:hover,
#bg__arrow_right:hover {
    background: rgba(0, 0, 0, .3);
    opacity: 1;
}


@media only screen and (max-width: 1200px) {
    #intro__bg img {
        width: unset;
        position: absolute;
        left: -50vh;
    }

    #btn__toStanze {
        margin: 0 2rem;
    }

    #bg__arrow_left,
    #bg__arrow_right {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .Intro {
        height: calc(1vh * 100);
        height: calc(var(--vh, 1vh) * 100);
    }
}
.Ecoturismo {
    height: auto;
    min-height: 100vh;
    width: 100%;
    padding: 3.5rem 3rem;
    color: var(--fontColor);
    display: flex;
    flex-direction: row;
}

#eco__left {
    height: 100%;
    width: 50%;
    /* border: 1px solid red; */
    margin-right: 2rem;
}

#eco__right {
    height: 100%;
    width: 40%;
    margin-left: 5rem;
    background: transparent;
    box-shadow: 10px 10px 0 var(--fontColor);
    z-index: 2;
    min-height: 80vh;
}

#eco__right_verona {
    min-height: 80vh;
    height: 100%;
    width: 100%;
    background: url(/static/media/verona_4.27bf27de.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    transition: var(--defaultTransition);
    cursor: pointer;
}

#eco__right_verona:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    -webkit-filter: drop-shadow(0 0 10px #000);
            filter: drop-shadow(0 0 10px #000);
}

#eco__right_verona:hover>#zoom_hover {
    opacity: 1;
}

#zoom_hover {
    height: 100%;
    min-height: 80vh;
    width: 100%;
    background: #2b2b2b99;
    opacity: 0;
    transition: var(--defaultTransition);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#zoom_label {
    margin-top: 2rem;
    color: white !important;
}

#eco__left_body {
    height: auto;
    width: 100%;
}

#eco__left_body p {
    width: auto;
    margin: 2rem 0 3rem 3rem;
    font-size: 1.3rem;
    font-family: Montserrat-Medium !important;
    text-align: justify;
    letter-spacing: 0 !important;
}

#eco__left_services {
    height: auto;
    width: 100%;
    display: grid;
    place-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 0px 0px;
    gap: 0px 0px;
}

.service {
    height: auto;
    width: 80%;
    margin: 2rem;
    /* border: 1px solid red; */
    padding: 1rem 2rem;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    font-size: 1rem;
    font-family: Montserrat-SemiBold;
    color: #000 !important;
    border-radius: 8px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, .3);
}

.service img {
    margin-right: 1rem;
    height: 3.5rem;
}

@media only screen and (max-width: 1200px) {
    .Ecoturismo {
        height: auto;
        flex-direction: column;
        align-items: center;
        padding: 2rem 1rem;
    }

    #eco__left {
        width: 100%;
        height: auto;
        min-height: 100vh;
        margin-right: 0;
        margin-bottom: 2rem;
    }

    #eco__right {
        width: 100%;
        max-height: 50vh;
        min-height: 50vh;
        margin-left: 0;
        background: url(/static/media/verona_4.27bf27de.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    #eco__left_body p {
        margin: 2rem 0;
        font-size: 1.5rem;
        text-align: start;
    }


    #eco__left_services {
        display: flex;
        flex-direction: column;
    }

    .service {
        margin: 0;
        margin-bottom: 2rem;
        box-shadow: none;
        border: 2px solid #000;
    }

    #zoom_hover {
        display: none;
    }

    #eco__right_verona {
        background: none;
    }

    #eco__right_verona:hover {
        -webkit-transform: unset;
                transform: unset;
        -webkit-filter: unset;
                filter: unset;
    }

}
.PageTitle {
    display: flex;
    align-items: center;
}

.PageTitle h1 {
    font-family: Montserrat-Bold;
    font-size: 3rem;
    z-index: 1;
    background: #fff;
    padding: 0 1rem;
    color: var(--fontColor);
}

/* .pageTitle_dark {
    background: #2e2e2e !important;
    text-shadow: 1px 1px 2px #fff;
}

.pageTitle_light {
    background: #fff !important;
} */

#header-line {
    height: 5px;
    width: 100%;
    background: var(--orangeKey);
    position: absolute;
    left: 0;
    z-index: 0;
}

@media only screen and (max-width: 1200px) {
    #header-line {
        display: none;
    }

    .PageTitle h1 {
        text-align: center;
        width: 100%;
        /* border-bottom: 5px solid var(--orangeKey); */
        -webkit-filter: drop-shadow(6px 6px 0 var(--orangeKey));
                filter: drop-shadow(6px 6px 0 var(--orangeKey));
        /* border-top: 5px solid var(--orangeKey); */
    }
}
.Modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 9999;
    display: grid;
    place-items: center;
    background: rgba(0, 0, 0, .9);
}

.modal__content {
    height: 90vh;
}

#modal__close {
    position: fixed;
    top: 2rem;
    right: 2rem;
    border: none;
    background: transparent;
    cursor: pointer;
    transition: var(--defaultTransition);
    opacity: .5;
}

#modal__close:hover {
    opacity: 1;
}

@media only screen and (max-width: 1200px) {
    .modal__content {
        width: 60%;
        height: auto;
    }
}

@media screen and (max-width: 600px) {
    .modal__content {
        width: 90%;
        height: auto;
    }

    .Modal {
        /* height: 100vh; */
        height: calc(1vh * 120);
        height: calc(var(--vh, 1vh) * 120);
    }
}
.Rooms {
    height: auto;
    width: 100%;
    padding: 0 3.5rem;
    color: var(--fontColor);
}

#orchidea_bianca,
#zaffiro,
#ametista {
    height: auto;
    width: 100%;
    margin-top: 5rem;
    display: flex;
    flex-direction: row;
}

.room_desc {
    width: 55%;
    height: auto;
    display: flex;
    flex-direction: column;
    font-size: 1.3rem;
}

.room_desc_1 {
    margin-bottom: 1rem;
}

.room_desc h1 {
    font-size: 2.5rem;
    font-family: Montserrat-Bold;
    letter-spacing: 2px;
    margin-bottom: 2rem;
}


.room_services {
    list-style-type: none;
    margin: .5rem 2rem;
    margin-bottom: 5rem;
}

.room_services li::before {
    /* content: '— '; */
    content: '- ';
}

.room_images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2fr 1fr;
    grid-auto-columns: 1fr;
    grid-gap: 10px 10px;
    gap: 10px 10px;
    grid-auto-flow: row;
    grid-template-areas:
        "room_img_1 room_img_1"
        "room_img_2 room_img_3";

    width: 45%;
    height: 100%;
    min-height: 80vh;
    margin-left: 2rem;
}

.room_images a {
    text-decoration: underline;
    font-family: Montserrat-SemiBold;
    opacity: .7;
    transition: var(--defaultTransition);
}

.room_images a:hover {
    opacity: 1;
}

.room_img_1 {
    grid-area: room_img_1;
    height: -webkit-min-content;
    height: min-content;
    margin: 0;
}

.room_img_2 {
    grid-area: room_img_2;
    height: -webkit-min-content;
    height: min-content;
}

.room_img_3 {
    grid-area: room_img_3;
    height: -webkit-min-content;
    height: min-content;
}

.room_img_1 img,
.room_img_2 img,
.room_img_3 img {
    width: 100%;
    transition: var(--defaultTransition);
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0);
}

.room_img_1 img:hover,
.room_img_2 img:hover,
.room_img_3 img:hover {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
    box-shadow: 0 0 15px rgba(0, 0, 0, .8);
}

.r_img_zaffiro {
    margin-left: 0;
    margin-right: 3rem;
}


@media only screen and (max-width: 1200px) {
    .Rooms {
        padding: 1rem;
    }

    #orchidea_bianca,
    #zaffiro,
    #ametista {
        flex-direction: column;
    }

    #zaffiro {
        flex-direction: column-reverse;
    }

    .room_desc,
    .room_images {
        width: 100% !important;
    }

    .room_images {
        margin: 0;
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

}
.GoBookings {
    margin-top: 5rem;
    height: 30rem;
    width: 100%;
    background: url(/static/media/banner.4f756278.png);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    padding: 2rem;
}

.GoBookings h1 {
    font-size: 3rem;
    font-family: Montserrat-Regular;
    letter-spacing: 2px;
    margin: 3rem 0;
}

.GoBookings a {
    border: 5px solid var(--orangeKey);
    font-size: 1.5rem;
    padding: 2rem;
    cursor: pointer;
    transition: var(--defaultTransition);
}

.GoBookings a:hover {
    background: var(--orangeKey);
}

@media only screen and (max-width: 1200px) {
    .GoBookings {
        text-align: center;
        height: 25rem;
    }

    .GoBookings a {
        padding: 1rem;
    }
}
.Gallery {
    height: auto;
    width: 100%;
    background: #2e2e2e;
    padding: 8rem 2rem 0 2rem;
}

.Gallery h1 {
    text-align: center;
    font-size: 3rem;
    margin: 0 0 3rem 1rem;
    color: #fff;
}

#gallery-container {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    column-width: 33%;
    padding: 0 12px;
}

.gallery-image {
    transition: var(--defaultTransition);
    margin-bottom: 12px;
    width: 100%;
    cursor: pointer;
}

.gallery-image:hover {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
}

#TagSelector {
    width: 100%;
    height: auto;
    padding: 1rem .5rem;
    margin-bottom: 2rem;
}

#tags_results {
    color: white;
    font-size: .8rem;
    margin-left: .5rem;
}

.btn__tagselector {
    margin-right: .5rem;
    margin-bottom: .5rem;
    padding: .6rem .8rem;
    font-family: Montserrat-SemiBold;
    color: #fff;
    background: rgba(255, 255, 255, .1);
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: var(--defaultTransition);
}

.btn__tagselector:hover {
    background: rgba(255, 255, 255, .4);
}

.btn__tagselector_active {
    background: rgba(255, 255, 255, .65);
    color: black;
}


@media screen and (max-width: 900px) {
    #gallery-container {
        -webkit-column-count: 2;
        column-count: 2;
    }

    .Gallery {
        padding: 5rem .5rem 0 .5rem;
        min-height: calc(1vh * 100);
        min-height: calc(var(--vh, 1vh) * 100);
    }

    .btn__tagselector {
        font-size: 1rem;
    }
}

@media screen and (max-width: 500px) {
    #gallery-container {
        -webkit-column-count: 1;
        column-count: 1;
    }
}
.Contacts {
    height: auto;
    width: 100%;
    padding: 2rem;
    color: var(--fontColor);
    margin-bottom: 2rem;
}

.Contacts p {
    font-size: 1.3rem;
    margin: 2rem 1rem;
}

#contact__boxes {
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-auto-columns: 1fr;
    grid-gap: 10px 10px;
    gap: 10px 10px;
    grid-auto-flow: row;
    grid-template-areas: "c__box_left c__box_mid c__box_right";
}

.contact__box {
    min-height: 30vh;
    border-right: 2px solid rgba(0, 0, 0, .3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.c__box_left {
    align-items: unset;
}

.c__box_left p {
    margin-left: 5rem;
}

#contacts__btn_box {
    display: flex;
    flex-direction: column;
}

.c__box_mid a {
    background: red;
    padding: 1rem;
    margin: 1rem 0;
    color: #fff;
    font-family: Montserrat-SemiBold;
    font-size: 1.3rem;
    /* width: 50%; */
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: var(--defaultTransition);
}

.c__box_mid a:hover {
    -webkit-filter: drop-shadow(0 0 8px rgba(0, 0, 0, .5));
            filter: drop-shadow(0 0 8px rgba(0, 0, 0, .5));
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.c__box_mid img {
    margin-right: 1rem;
}

.c__box_right {
    border: none;
}

.c__box_right iframe {
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 1200px) {
    #contact__boxes {
        display: flex;
        flex-direction: column;
    }

    .contact__box {
        border-right: unset;
        border-bottom: 2px solid rgba(0, 0, 0, .3);
    }

    .c__box_mid a:hover {
        -webkit-filter: unset;
                filter: unset;
        -webkit-transform: unset;
                transform: unset;
    }

    .c__box_mid a:active {
        -webkit-filter: drop-shadow(0 0 8px rgba(0, 0, 0, .5));
                filter: drop-shadow(0 0 8px rgba(0, 0, 0, .5));
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
    }
}
.Footer {
    /* height: 25vh; */
    height: auto;
    width: 100%;
    background: #2e2e2e;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 2px solid #fff;
    padding: 1rem 0;
}

.footer__box {
    width: auto;
    height: 100%;
    /* border-right: 2px solid rgba(255, 255, 255, .5); */
    padding: 1rem 2rem;
    color: #fff;
}

.footer__divider {
    height: 180px;
    width: 2px;
    background: rgba(255, 255, 255, .5);
}

.footer__logo {
    display: grid;
    place-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.footer__logo img {
    width: 70%;
}

.footer__box h3 {
    opacity: .5;
    font-family: Montserrat-Regular;
}

.footer__privacy {
    border: none;
}

.footer__links div,
.footer__privacy div {
    display: flex;
    flex-direction: column;
    margin: .5rem;
}

.footer__links a,
.footer__privacy a {
    margin: .3rem 0;
    cursor: pointer;
}

.footer__links a:hover,
.footer__privacy a:hover {
    text-decoration: underline;
}

#footer__contacts_box {
    display: flex;
    flex-direction: row;
    margin-top: .5rem;
    line-height: 1.3rem;
}

#footer__contacts_box div {
    margin: 0 1rem;
}

#footer__contacts_box a {
    opacity: .5;
    transition: var(--defaultTransition);
    cursor: pointer;
}

#footer__contacts_box a:hover {
    opacity: 1;
    text-decoration: underline;
}

@media screen and (max-width: 1400px) {
    #footer__contacts_box div {
        margin: 0 .5rem;
    }

    .footer__logo img {
        width: 100%;
    }

    .footer__logo {
        width: 20%;
    }
}

@media screen and (max-width: 1200px) {
    .footer__links {
        display: none;
    }
}

@media screen and (max-width: 900px) {
    .footer__divider {
        display: none;
    }

    .footer__box div {
        align-items: flex-start;
    }

    .Footer {
        flex-direction: column;
        align-items: flex-start;
    }

    .footer__logo {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    #footer__contacts_box {
        flex-direction: column;
    }

    #footer__contacts_indirizzo a {
        line-height: 3rem;
    }
}
.Notification {
    height: auto;
    width: auto;
    max-width: 30%;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    background: #fff;
    border-radius: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    font-size: 1rem;
    z-index: 9998;
}

.Notification img {
    margin-right: 1rem;
    height: 150%;
}

#not_1 {
    border: none;
    background: none;
    position: absolute;
    top: .5rem;
    right: .5rem;
    opacity: .5;
    transition: var(--defaultTransition);
    cursor: pointer;
    font-size: 1.1rem;
}

#not_1:hover {
    opacity: 1;
}

#not_2 {
    display: none;
    border: none;
    background: none;
    position: absolute;
    bottom: .5rem;
    right: .5rem;
    cursor: pointer;
    font-size: 1rem;
    border: 2px solid var(--orangeKey);
    padding: 5px;
}

@media only screen and (max-width: 900px) {
    .Notification {
        bottom: 0;
        left: 0;
        width: 100%;
        max-width: unset;
        border-radius: 0;
        box-shadow: 0 -5px 10px rgba(0, 0, 0, .3);
    }

    #not_1 {
        display: none;
    }

    #not_2 {
        display: unset;
    }
}
.Privacy {
    min-height: 100vh;
    height: auto;
    width: 100%;
    background: var(--darkKey);
    padding: 8rem 8rem 1rem 8rem;
    color: white;
}

.privacy_h1 {
    font-size: 3rem;
}

.privacy_p {
    width: 100%;
    margin-bottom: 2rem;
}

.privacy_link {
    text-decoration: underline;
    opacity: .6;
    transition: var(--defaultTransition);
}

.privacy_link:hover {
    opacity: 1;
}

.privacy_ul {
    margin-left: 2rem;
}

@media only screen and (max-height: 800px) {
    .Privacy {
        padding: 3rem 2rem 1rem 2rem;
    }
}
.NotFound {
    height: 100vh;
    width: 100%;
    background: #2e2e2e;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#notfound-info a {
    margin: 1rem 0;
    text-decoration: underline;
}
