.Intro {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    background-image: url('../../assets/images/bg5.png');
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    transition: .3s ease-in;
}

#intro__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#logo {
    width: 50vh;
    margin-bottom: 10vh;
    animation: 1s logoFadeIn;
}

@keyframes logoFadeIn {
    from {
        margin-right: 10%;
        opacity: 0;
    }

    to {
        margin-right: unset;
        opacity: 1;
    }
}

#btn__toStanze {
    font-size: 1.5rem;
    text-align: center;
    padding: 1.5rem;
    border: 5px solid var(--orangeKey);
    cursor: pointer;
    background: transparent;
    transition: var(--defaultTransition);
    position: relative;
    animation: 1s btnToStanzeFadeIn;
}

@keyframes btnToStanzeFadeIn {
    from {
        margin-left: 10%;
        opacity: 0;
    }

    to {
        margin-left: unset;
        opacity: 1;
    }
}

#btn__toStanze:hover {
    background-color: var(--orangeKey);
}

/* #btn__toStanze span::after {
    content: '»';
    position: absolute;
    top: 1.5rem;
    margin-left: 0;
    opacity: 0;
    transition: var(--defaultTransition);
}

#btn__toStanze:hover span {
    margin-right: 1rem;
    transition: var(--defaultTransition);
}

#btn__toStanze:hover span::after {
    margin-left: .5rem;
    opacity: 1;
} */

#bg__arrow_right {
    right: 0;
}

#bg__arrow_left,
#bg__arrow_right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 6rem;
    cursor: pointer;
    transition: var(--defaultTransition);
    display: grid;
    place-items: center;
    background: transparent;
    border: none;
    border-radius: 5px;
    opacity: .5;
}

#bg__arrow_left img,
#bg__arrow_right img {
    height: 3.5rem;
}

#bg__arrow_left:hover,
#bg__arrow_right:hover {
    background: rgba(0, 0, 0, .3);
    opacity: 1;
}


@media only screen and (max-width: 1200px) {
    #intro__bg img {
        width: unset;
        position: absolute;
        left: -50vh;
    }

    #btn__toStanze {
        margin: 0 2rem;
    }

    #bg__arrow_left,
    #bg__arrow_right {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .Intro {
        height: calc(var(--vh, 1vh) * 100);
    }
}